import { createSlice } from '@reduxjs/toolkit';
import instance from '../../../utils/erpAxios';


const initialState = {
    currencies: [],
    expenseItems: []
};


export const expenseSlice = createSlice({
    name: 'expenses',
    initialState,
    reducers: {
        setAllCurrencies: (state, action) => {
            state.currencies = action.payload;
        },
        setAllExpenseItems: (state, action) => {
            state.expenseItems = action.payload;
        }
    }
});

export const getAllCurrencies = () => async (dispatch) => {
    try {
        const response = await instance.get('/currencies');
        dispatch(expenseSlice.actions.setAllCurrencies(response.data));
    } catch (error) {
        return dispatch(expenseSlice.actions.setAllCurrencies(initialState.allUsers));
    }
};

export const getAllExpenseItems = () => async (dispatch) => {
    try {
        const response = await instance.get('/expenses');
        dispatch(expenseSlice.actions.setAllExpenseItems(response.data));
    } catch (error) {
        return dispatch(expenseSlice.actions.setAllExpenseItems(initialState.allUsers));
    }
};

export const clearExpenseState = () => async (dispatch) => {
    dispatch(expenseSlice.actions.setAllExpenseItems(initialState.allUsers));
    dispatch(expenseSlice.actions.setAllCurrencies(initialState.allUsers));
    return;
};

export const {setAllCurrencies, setAllExpenseItems} = expenseSlice.actions;

export default expenseSlice.reducer;
