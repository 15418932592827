import { createSlice } from '@reduxjs/toolkit';
import instance from '../../../utils/erpAxios';


const initialState = {
    personnel: [],
    admins: [],
    categories: [],
    statuses: [],
};


export const itSettingsSlice = createSlice({
    name: 'itsettings',
    initialState,
    reducers: {
        setAllPersonnel: (state, action) => {
            state.personnel = action.payload;
        },
        setAllAdmins: (state, action) => {
            state.admins = action.payload;
        },
        setAllCategories: (state, action) => {
            state.categories = action.payload;
        },
        setAllStatuses: (state, action) => {
            state.statuses = action.payload;
        },
    }
});

export const getAllPersonnel = () => async (dispatch) => {
    try {
        const response = await instance.get('/it/personnel');
        dispatch(itSettingsSlice.actions.setAllPersonnel(response.data[0].userIds));
        dispatch(itSettingsSlice.actions.setAllAdmins(response.data[0].adminIds));
    } catch (error) {
        dispatch(itSettingsSlice.actions.setAllPersonnel(initialState.personnel));
        dispatch(itSettingsSlice.actions.setAllAdmins(initialState.admins));
        return;
    }
};

export const getAllCategories = () => async (dispatch) => {
    try {
        const response = await instance.get('/it/categories');
        dispatch(itSettingsSlice.actions.setAllCategories(response.data));
    } catch (error) {
        return dispatch(itSettingsSlice.actions.setAllCategories(initialState.categories));
    }
} 

export const getAllStatuses = () => async (dispatch) => {
    try {
        const response = await instance.get('/it/statuses');
        dispatch(itSettingsSlice.actions.setAllStatuses(response.data));
    } catch (error) {
        return dispatch(itSettingsSlice.actions.setAllStatuses(initialState.statuses));
    }
}

export const clearITSettingsState = () => async (dispatch) => {
    dispatch(itSettingsSlice.actions.setAllPersonnel(initialState.personnel));
    dispatch(itSettingsSlice.actions.setAllCategories(initialState.categories));
    dispatch(itSettingsSlice.actions.setAllStatuses(initialState.statuses));
    return;
};

export const {setAllPersonnel, setAllCategories, setRolePermissions, setAllStatuses} = itSettingsSlice.actions;

export default itSettingsSlice.reducer;
