import { useCallback, useContext } from 'react';
import { SnackbarContext } from '../contexts/SnackbarContext';

const useSnackbar = () => {
    const { setMessage, setOpen, setVariant, setOptions } = useContext(SnackbarContext);

    const openSnackbar = useCallback(
        (variant, message, options) => {
            setMessage(message);
            setVariant(variant);
            setOptions(options);
            setOpen(true);
        },
        [setMessage, setOpen, setOptions, setVariant]
    );

    return {
        openSnackbar
    };
};

export default useSnackbar;
