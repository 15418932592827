import { createSlice } from '@reduxjs/toolkit';
import instance from '../../../utils/erpAxios';


const initialState = {
    roles: [],
    users: [],
    permissions: [
        {
            module: 'users',
            view: false,
            edit: false,
            del: false
        },
        {
            module: 'expenses',
            view: false,
            edit: false,
            del: false
        },
        {
            module: 'branches',
            view: false,
            edit: false,
            del: false
        },
        {
            module: 'expense-sheets',
            view: false,
            edit: false,
            del: false
        },
        {
            module: 'expenses-summary',
            view: false,
            edit: 'disabled',
            del: 'disabled'
        },
        {
            module: 'expenses-by-branch',
            view: false,
            edit: 'disabled',
            del: 'disabled'
        },
        {
            module: 'monthly-expenses',
            view: false,
            edit: 'disabled',
            del: 'disabled'
        },
        {
            module: 'report-builder',
            view: false,
            edit: false,
            del: 'disabled'
        },
        {
            module: 'hotspot-overview',
            view: false,
            edit: false,
            del: false
        },
        {
            module: 'camera-logs',
            view: false,
            edit: 'disabled',
            del: 'disabled'
        },
        {
            module: 'it-support-overview',
            label: 'IT Support Overview',
            view: false,
            edit: 'disabled',
            del: 'disabled'
        },
        {
            module: 'it-support-tickets',
            label: 'IT Support Tickets',
            view: false,
            edit: false,
            del: false
        },
        {
            module: 'it-support-settings',
            label: 'IT Support Settings',
            view: false,
            edit: false,
            del: 'disabled'
        },
        {
            module: 'directory',
            label: 'Directory',
            view: false,
            edit: false,
            del: false
        }
    ],
    selectedRole: {},
};


export const userSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        setAllUsers: (state, action) => {
            state.users = action.payload;
        },
        setAllRoles: (state, action) => {
            state.roles = action.payload;
        },
        setRolePermissions: (state, action) => {
            const { roleDescription, permissions } = action.payload;
            const index = state.roles.findIndex(r => r.roleDescription === roleDescription);
            state.roles[index].permissions = permissions;
        },
        setSelectedRole: (state, action) => {
            state.selectedRole = action.payload;
        }
    }
});

export const getAllUsers = () => async (dispatch) => {
    try {
        const response = await instance.get('/users');
        dispatch(userSlice.actions.setAllUsers(response.data));
    } catch (error) {
        return dispatch(userSlice.actions.setAllUsers(initialState.allUsers));
    }
};

export const getAllRoles = () => async (dispatch) => {
    try {
        const response = await instance.get('/users/roles');
        dispatch(userSlice.actions.setAllRoles(response.data));
    } catch (error) {
        return dispatch(userSlice.actions.setAllRoles(initialState.roles));
    }
} 

export const createNewRole = (roleDescription) => async (dispatch) => {
    try {
        const response = await instance.post('/users/modifyRole', { roleDescription, permissions: initialState.permissions });
        dispatch(userSlice.actions.setAllRoles(response.data));
    } catch (error) {
        return dispatch(userSlice.actions.setAllRoles(initialState.roles));
    }
}

export const modifyRole = (roleDetails) => async (dispatch) => {
    try {
        const response = await instance.post('/users/modifyRole', { ...roleDetails });
        dispatch(userSlice.actions.setAllRoles(response.data));
    } catch (error) {
        return dispatch(userSlice.actions.setAllRoles(initialState.roles));
    }
}

export const clearUserState = () => async (dispatch) => {
    dispatch(userSlice.actions.setAllUsers(initialState.users));
    dispatch(userSlice.actions.setAllRoles(initialState.roles));
    dispatch(userSlice.actions.setSelectedRole(initialState.selectedRole));
    return;
};

export const {setAllUsers, setAllRoles, setRolePermissions, setSelectedRole} = userSlice.actions;

export default userSlice.reducer;
