import { useTheme } from '@emotion/react'
import { Divider, Drawer, List, ListItem, Stack, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React, { Fragment } from 'react'
import { Calendar, DollarSign, Grid, User, MapPin, PieChart, Table, FileText, BookOpen } from 'react-feather'
import { useDispatch } from 'react-redux'
import { NavLink as RouterLink, useLocation } from 'react-router-dom'
import { useAuth } from '../../../authentication/useAuth'
import { setDrawerOpen } from '../../../store/global';

export const sections = [
    {
        header: 'Administration',
        subSections: [
            {
                title: 'Users',
                permission: 'Users',
                icon: User,
                href: '/erp/administration/users'
            },
            {
                title: 'Expenses',
                permission: 'Expenses',
                icon: DollarSign,
                href: '/erp/administration/expenses'
            },
            {
                title: 'Branches',
                permission: 'Branches',
                icon: Grid,
                href: '/erp/administration/branches'
            },
        ]
    },
    {
        header: 'Expenses',
        subSections: [
            {
                title: 'Summary',
                permission: 'Expenses Summary',
                icon: PieChart,
                href: '/erp/expenses/expenses-summary'
            },
            {
                title: 'Monthly Report',
                permission: 'Monthly Expenses',
                icon: Calendar,
                href: '/erp/expenses/monthly-expenses'
            },
            {
                title: 'Sheets',
                permission: 'Expense Sheets',
                icon: Table,
                href: '/erp/expenses/expense-sheets'
            },
            {
                title: 'Branch Report',
                permission: 'Branch Expenses',
                icon: MapPin,
                href: '/erp/expenses/branch-expenses'
            },
            {
                title: 'Report Builder',
                permission: 'Report Builder',
                icon: FileText,
                href: '/erp/expenses/report-builder'
            }
        ]
    },
    // {
    //     header: 'Hotspot',
    //     subSections: [
    //         {
    //             title: 'Overview',
    //             permission: 'Hotspot Overview',
    //             icon: Eye,
    //             href: '/erp/hotspot/hotspot-overview'
    //         },
    //     ]
    // },
    // {
    //     header: 'CCTV',
    //     subSections: [
    //         {
    //             title: 'Logs',
    //             permission: 'Camera Logs',
    //             icon: FileText,
    //             href: '/erp/hotspot/camera-logs'
    //         },
    //     ]
    // },
    // {
    //     header: 'IT Support',
    //     subSections: [
    //         {
    //             title: 'Overview',
    //             permission: 'IT Support Overview',
    //             icon: Eye,
    //             href: '/erp/it-support/it-support-overview'
    //         },
    //         {
    //             title: 'Tickets',
    //             permission: 'IT Support Tickets',
    //             icon: FileText,
    //             href: '/erp/it-support/it-support-tickets'
    //         },
    //         {
    //             title: 'Settings',
    //             permission: 'IT Support Settings',
    //             icon: Settings,
    //             href: '/erp/it-support/it-support-settings'
    //         }
    //     ]
    // },
    {
        header: 'Human Resources',
        subSections: [
            {
                title: 'Directory',
                permission: 'Directory',
                icon: BookOpen,
                href: '/erp/human-resources/directory'
            }
        ]
    }
]

const NavBar = () => {
    const { user } = useAuth();
    const dispatch = useDispatch();
    const { pathname } = useLocation();
    const theme = useTheme();

    const [, erp, , path] = pathname.split('/')
    const isSectionAuthorized = (section) => {
        let authorized = false;
        section?.subSections?.forEach(element => {
            const module = element.permission.toLowerCase().replace(/ /g, "-");
            const check = (user?.userPermissions?.find(p => p.module === module)?.view && user?.userPermissions?.find(p => p.module === module)?.edit) || false;
            if (check) authorized = true;
        });
        return authorized;
    };

    const isSubSectionAuthorized = (subsection) => {
        let authorized = false
        const module = subsection.permission.replace(/ /g, "-").toLowerCase();
        const check = (user?.userPermissions?.find(p => p.module === module)?.view && user?.userPermissions?.find(p => p.module === module)?.edit) || false;
        if (check) authorized = true;
        return authorized;
    }

    return (
        <Drawer
            open={true}
            variant="permanent"
            anchor='left'
            elevation={2}
            sx={{
                overflowX: 'hidden',
                width: 200,
                [`& .MuiDrawer-paper`]: {
                    width: 200, 
                    overflowX: 'hidden',
                    // borderRadius: isSmallScreen ? 0 : '0px 32px 32px 0px',
                    backgroundColor: theme.palette.primary.main,
                },
            }}
        >
            <Stack gap={0} justifyContent={"flex-start"}>
                <Box
                    component={RouterLink}
                    to={'/erp'}
                    p={1}
                    display="flex"
                    alignItems={"center"}
                    justifyContent="center"
                    onClick={() => dispatch(setDrawerOpen())}
                >
                    <img src={"/logo_white.png"} alt="logo" style={{ width: '50%', height: 'auto' }} />
                </Box>
                <Box
                    sx={{
                        overflowY: 'auto',
                        maxHeight: 'calc(100vh - 100px)',
                        '& .MuiDivider-root': {
                            backgroundColor: theme.palette.primary.light,
                        },
                    }}
                >
                    <List>
                        <ListItem style={{ mt: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Box
                                component={RouterLink}
                                to={'/erp'}
                                color={'inherit'}
                                display="flex"
                                width="100%"
                                justifyContent="center"
                                alignItems="center"
                                py={1}
                                borderRadius={4}
                                sx={
                                    erp === 'erp' && path == null ?
                                        {
                                            textDecoration: "none",
                                            backgroundColor: 'white',
                                            color: theme.palette.primary.main,
                                        } :
                                        {
                                            textDecoration: "none",
                                            color: "white",
                                            '&:hover': {
                                                backgroundColor: "white",
                                                color: theme.palette.primary.main
                                            }
                                        }
                                }
                                onClick={() => dispatch(setDrawerOpen())}
                            >
                                <Typography fontSize={18}>Dashboard</Typography>
                            </Box>
                        </ListItem>
                        <Divider variant='middle' />
                        {sections.map((section, index) => {
                            return (
                                isSectionAuthorized(section) ?
                                    <Fragment key={index}>
                                        <ListItem style={{ mt: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                            <Box
                                                color={'inherit'}
                                                display="flex"
                                                width="100%"
                                                justifyContent="center"
                                                alignItems="center"
                                                py={1}
                                                borderRadius={4}
                                                sx={
                                                        {
                                                            textDecoration: "none",
                                                            color: "white",
                                                        }
                                                }
                                            >
                                                <Typography fontWeight={500} fontSize={18}>{section.header}</Typography>
                                            </Box>
                                        </ListItem>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    width: '100%',
                                                    gap: 0,
                                                }}
                                            >
                                                {section.subSections.map(sub => {
                                                    const [, , , subRef] = sub.href.split('/');
                                                    const Icon = sub.icon
                                                    const SubIcon = sub.subIcon
                                                    return (
                                                        isSubSectionAuthorized(sub) ?
                                                            <ListItem sx={{ py: '4px', width: '100%', }} disableGutters key={sub.href}>
                                                                <Box
                                                                    component={RouterLink}
                                                                    to={sub.href}
                                                                    color={path !== subRef ? "white" : theme.palette.primary.dark}
                                                                    display="flex"
                                                                    width="100%"
                                                                    flexDirection="row"
                                                                    justifyContent="flex-start"
                                                                    alignItems="center"
                                                                    gap={1}
                                                                    p={1}
                                                                    px={1.5}
                                                                    borderRadius={4}
                                                                    ml={1.5}
                                                                    mr={1.5}
                                                                    sx={
                                                                        path === subRef ?
                                                                            {
                                                                                textDecoration: "none",
                                                                                backgroundColor: "white",
                                                                            }
                                                                            :
                                                                            {
                                                                                textDecoration: "none",
                                                                                '&:hover': {
                                                                                    backgroundColor: "white",
                                                                                    color: theme.palette.primary.main
                                                                                }
                                                                            }
                                                                    }
                                                                    onClick={() => dispatch(setDrawerOpen())}
                                                                >
                                                                    <Stack direction="row" gap={0} display="flex" alignItems={"flex-end"} >
                                                                        <Icon size={20} style={{ paddingRight: 0 }} />
                                                                        {sub.subIcon ?
                                                                            <SubIcon size={12} />
                                                                            : null
                                                                        }
                                                                    </Stack>
                                                                    <Typography fontSize={14}>{sub.title}</Typography>
                                                                </Box>
                                                            </ListItem>
                                                            : null
                                                    )
                                                })}
                                            </Box>
                                        {
                                            index < sections.length - 1 ?
                                                <Divider variant='middle' />
                                                : null
                                        }
                                    </Fragment>
                                    : null
                            )
                        })}
                    </List>
                </Box>
            </Stack>
        </Drawer>
    )
}

export default NavBar