import { Alert, Slide, Snackbar as MuiSnackbar } from '@mui/material';
import { createContext, useState } from 'react';

export const SnackbarContext = createContext({
    snackbarProps: {
        open: false,
        autoHideDuration: 5000,
        message: '',
        variant: '',
        anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right'
        },
        sx:{'& .MuiSnackbar-root': { top: '60px', right: '10px'}},
        onClose: () => {}
    },
    setMessage: () => {},
    setOpen: () => {},
    setOptions: () => {},
    setVariant: () => {}
});

const Snackbar = (props) => {
    const { variant, message } = props || {};
    return (
        <MuiSnackbar {...props} TransitionComponent={Slide} >
            <Alert severity={variant} sx={{ width: '100%' }}>
                {message}
            </Alert>
        </MuiSnackbar>
    );
};

export const SnackbarProvider = ({ children }) => {
    const { snackbarProps, ...rest } = useProvideSnackbarContext();
    return (
        <SnackbarContext.Provider value={rest}>
            <Snackbar {...snackbarProps} />
            {children}
        </SnackbarContext.Provider>
    );
};

const useProvideSnackbarContext = () => {
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [options, setOptions] = useState({});
    const [variant, setVariant] = useState('info');

    return {
        snackbarProps: {
            open,
            autoHideDuration: 5000,
            message,
            variant,
            anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'right'
            },
            sx:{'& .MuiSnackbar-root': { top: '60px', right: '10px'}},
            onClose: () => setOpen(false),
            ...options
        },
        setMessage,
        setOpen,
        setOptions,
        setVariant
    };
};
