import { createSlice } from '@reduxjs/toolkit';
import instance from '../../utils/erpAxios';

const initialState = {
    expenseSheets: [],
    employeeDetails: []
};


export const sheetSlice = createSlice({
    name: 'expenses',
    initialState,
    reducers: {
        setAllExpenseSheets: (state, action) => {
            state.expenseSheets = action.payload;
        },
        setAllEmployeeDetails: (state, action) => {
            state.employeeDetails = action.payload;
        }
    }
});

export const getAllExpenseSheets = (userId) => async (dispatch) => {
    try {
        const employeeDetailsResponse = await instance.get(`/expenses/employeeDetails/${userId}`);
        dispatch(sheetSlice.actions.setAllEmployeeDetails(employeeDetailsResponse.data));
        const response = await instance.get(`/expenses/expense-sheets/${userId}`);
        dispatch(sheetSlice.actions.setAllExpenseSheets(response.data));
    } catch (error) {
        return dispatch(sheetSlice.actions.setAllExpenseSheets(initialState.allUsers));
    }
};

export const clearSheetState = () => async (dispatch) => {
    return dispatch(sheetSlice.actions.setAllExpenseSheets(initialState.allUsers));
};

export const {setAllExpenseSheets} = sheetSlice.actions;

export default sheetSlice.reducer;
