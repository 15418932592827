import { AppBar, Hidden, Toolbar } from '@mui/material';
import { Box } from '@mui/system';
import dayjs from 'dayjs';
import WeekOfYear from 'dayjs/plugin/weekOfYear';
import React from 'react';
import { Menu } from 'react-feather';
import { useDispatch } from 'react-redux';
// import useIsSmallScreen from '../../../hooks/useIsSmallScreen';
import { setDrawerOpen } from '../../../store/global';
// import NotificationButton from './NotificationButton';
// import BreadcrumbsSection from './BreadCrumbsSection';
import ProfileButton from './ProfileButton';
import QuickAddDrawer from './QuickAddDrawer';
import SubMenuSection from './SubMenuSection';
import { NavLink as RouterLink } from 'react-router-dom'
// import IF from '../../../components/IF';
// import useIsTablet from '../../../hooks/useIsTablet';
// import useOnlineStatus from '../../../hooks/useOnlineStatus';

const TopBar = () => {
    dayjs.extend(WeekOfYear);
    const dispatch = useDispatch();
    // const isSmallScreen = useIsSmallScreen();
    // const isOnline = useOnlineStatus();
    const [open, setOpen] = React.useState(false);

    return (
        <>
            <QuickAddDrawer open={open} setOpen={setOpen} />
            <AppBar
                position="fixed"
                sx={{ height: '64px', justifyContent: "center", alignItems: "space-between" }}
                color="primary"
                elevation={16}
            >
                <Toolbar variant="dense" sx={{ alignItems: "center", justifyContent: 'flex-start', gap: 2 }}>
                    {/* <Hidden xlUp> */}
                    {

                        <Box left="24px" display={"flex"} alignSelf={"center"}>
                            <Menu onClick={() => dispatch(setDrawerOpen())} color="white" />
                        </Box>

                    }
                    {/* </Hidden> */}
                    <Box
                        component={RouterLink}
                        to={'/erp'}
                        sx={{
                            // position: 'absolute',
                            // right: 146,
                            display: 'flex',
                            alignItems: 'center',
                            flexDirection: 'column',
                            // backgroundColor: theme => theme.palette.secondary.main,
                            padding: 1,
                            py: 0.5,
                            // borderRadius: 2,
                            textDecoration: 'none',
                            color: theme => theme.palette.secondary.main,
                        }}
                    >
                        <img src="/Asset 1.svg" alt="logo" style={{ height: '40px' }} />
                    </Box>
                    <Hidden mdDown>
                        <SubMenuSection />
                    </Hidden>
                    {/* <Button 
                            sx={{
                                position: "fixed",
                                right: '132px',
                                padding: '4px',
                                px: '8px',
                                mr: '16px',
                                backgroundColor: theme => theme.palette.secondary.main,
                                color: 'white',
                                borderRadius: '8px',
                                '&:hover': {
                                    backgroundColor: theme => theme.palette.secondary.dark,
                                },
                            }}
                            onClick={() => setOpen(true)}
                        >
                            <Stack direction="row" gap={0.5} alignItems="center">
                                <Plus size={18} />
                                Quick Add
                            </Stack>
                        </Button> */}
                    {/* <NotificationButton sx={{ position: "fixed", right: '84px', padding: '4px', mr: '10px' }} /> */}
                    <ProfileButton sx={{ position: "fixed", right: '16px', padding: '4px', mr: '10px' }} />
                    
                </Toolbar>
            </AppBar>
        </>
    );
};

export default TopBar;
