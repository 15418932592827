import { Box, CircularProgress } from '@mui/material';
import React from 'react';

const SplashScreen = () => {
    return (
        <Box
            sx={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                justifyContent: 'center',
                left: 0,
                position: 'fixed',
                top: 0,
                width: '100%',
                zIndex: 2000
            }}
        >
            <CircularProgress size={64} color="primary" />
        </Box>
    );
};

export default SplashScreen;
