import { Paper, Stack, TextField, Typography, useTheme, Button, Hidden } from '@mui/material'
import { Box } from '@mui/system'
import React, { useState } from 'react'
import { AlertCircle, Eye, EyeOff } from 'react-feather';
import { useAuth } from '../../authentication/useAuth';
import IF from '../../components/IF';
import useIsMobile from '../../hooks/useIsMobile';
import useIsTablet from '../../hooks/useIsTablet';

const Login = () => {
    const { login } = useAuth();
    const [userName, setUserName] = useState("");
    const [password, setPassword] = useState("");
    const [viewPassword, setViewPassword] = useState(false);
    const [error, setError] = useState(null);
    const theme = useTheme();
    const isTablet = useIsTablet();
    const isMobile = useIsMobile();

    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            await login({ userName, password });
        } catch (error) {
            setError(error.message);
        }
    };

    return (
        <Box
            width="100%"
            height={isTablet ? '100%' : '100vh'}
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection={isTablet ? "column" : "row"}
            gap={isTablet ? 4 : 0}
            backgroundColor={isTablet ? theme.palette.primary.main : null}
        >

            <Hidden mdDown>
                <div
                    style={{
                        background: theme.palette.primary.main,
                        width: '100%',
                        height: isTablet ? '20vh' : '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <img src='/Asset 1.svg' alt="logo" width="80%" color='white' />

                </div>

            </Hidden>
            <Hidden mdUp>
                <div
                    style={{
                        background: theme.palette.primary.main,
                        width: '100%',
                        height: isTablet ? '20vh' : '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: 8
                    }}
                >
                    <img src='/logo_white.png' alt="logo" width="50%" height={"auto"} color='white' />
                </div>
            </Hidden>
            <div
                style={{
                    height: 'fit-content',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    // backgroundImage: isTablet ? 'linear-gradient(to bottom, #312783 50%, #edf2f4 50%)' : null
                }}
            >
                <Paper
                    sx={{
                        boxShadow: theme.shadows[24],
                        // padding: theme.spacing(4),
                        p: 4,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: theme.spacing(2),
                        minHeight: '40vh',
                        minWidth: isTablet ? '70%' : '30vw',
                        maxWidth: isTablet ? '70%' : '30vw',
                    }}
                >
                    <form onSubmit={handleLogin}>
                        <Stack width="100%" direction="column" gap={isMobile ? 2 : 4} justifyContent="space-between" alignItems="flex-start">
                            <Typography style={{ paddingTop: 8 }} align='left' color={'grey'} variant="body1">Welcome to the Moor's World of Sport ERP system</Typography>
                            <Typography align='left' color={theme.palette.primary.dark} variant="h5">Log into your Account</Typography>
                            <TextField fullWidth label="Username" value={userName} autoComplete="username" onChange={(event) => setUserName(event.target.value)} />
                            <TextField
                                fullWidth
                                label="Password"
                                type={viewPassword ? "text" : "password"}
                                value={password}
                                autoComplete="current-password"
                                onChange={(event) => setPassword(event.target.value)} 
                                InputProps={{
                                    endAdornment: 
                                        <Box
                                            onClick={() => setViewPassword(!viewPassword)}
                                            sx={{ cursor: 'pointer' }}
                                        >
                                            {
                                                viewPassword ? <EyeOff /> : <Eye />
                                            }
                                        </Box>
                                }}
                            />
                            <Box
                                display="flex"
                                justifyContent="flex-end"
                                alignItems="center"
                                width={'100%'}
                            >
                                <Button size="large" sx={{ minWidth: '40%', maxWidth: '50%' }} type="submit" variant="contained">Login</Button>
                            </Box>
                            <IF condition={error}>
                                <Box display="flex" width="100%" height={'5vh'} gap={2} justifyContent="flex-end" alignItems="flex-end">
                                    <AlertCircle size={16} color={theme.palette.error.main} />
                                    <Typography variant="caption" color="error.main">
                                        {error}
                                    </Typography>
                                </Box>
                            </IF>
                            <IF condition={!error}>
                                <Box display="flex" width="100%" height={'5vh'} gap={2} alignItems="center">
                                </Box>
                            </IF>
                        </Stack>
                    </form>
                </Paper>
            </div>
            <div
                style={{
                    position: 'fixed',
                    bottom: 0,
                    right: 0,
                    width: '70vw',
                    padding: theme.spacing(2),
                }}
            >
                <Stack direction={"row"} justifyContent="flex-end">
                    <Typography align='left' color={'grey'} variant="h6">v1.1.0</Typography>
                </Stack>
            </div>
        </Box >
    )
}

export default Login