import { createSlice } from '@reduxjs/toolkit';
import instance from '../../utils/erpAxios';

const initialState = {
    expensesSummaryData: [],
    monthlyReportData: []
};


export const reportSlice = createSlice({
    name: 'reports',
    initialState,
    reducers: {
        setExpensesSummaryData: (state, action) => {
            state.expensesSummaryData = action.payload;
        },
        setMonthlyReportData: (state, action) => {
            state.monthlyReportData = action.payload;
        }
    }
});

export const getExpensesSummaryData = (month) => async (dispatch) => {
    try {
        if(month) {
            const response = await instance.get(`/reports/expenses-summary/`, {params: {month}});
            dispatch(reportSlice.actions.setExpensesSummaryData(response.data[0]));
        }
        else {
            dispatch(reportSlice.actions.setExpensesSummaryData(initialState.expensesSummaryData));
        }
    } catch (error) {
        return dispatch(reportSlice.actions.setExpensesSummaryData(initialState.expensesSummaryData));
    }
};

export const getMonthlyReportData = () => async (dispatch) => {
    try {
            const response = await instance.get(`/reports/monthly-expenses/`);
            dispatch(reportSlice.actions.setMonthlyReportData(response.data[0]));
    } catch (error) {
        return dispatch(reportSlice.actions.setMonthlyReportData(initialState.monthlyReportData));
    }
};

export const clearAllReportState = () => async (dispatch) => {
    dispatch(reportSlice.actions.setExpensesSummaryData(initialState.expensesSummaryData));
    dispatch(reportSlice.actions.setMonthlyReportData(initialState.monthlyReportData));
    return;
};

export const {setExpensesSummaryData, setMonthlyReportData} = reportSlice.actions;

export default reportSlice.reducer;
