import { Box, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import React from 'react'
import { NavLink as RouterLink, useLocation } from 'react-router-dom';
import { useAuth } from '../../../authentication/useAuth';
import { toTitleCase } from '../../../utils/stringFunctions';
import { sections } from '../NavBar'

const getSections = (pathName) => {
    let splitLocation = pathName.split('/').slice(2, pathName.length);
    if (splitLocation.length === 0) {
        return [{ name: 'Dashboard' }];
    }
    return splitLocation.map((value, index) => {
        if (index === splitLocation.length - 1) {
            return { name: toTitleCase(value, '-') };
        }
        if (index === 1) {
            
            return {
                name: toTitleCase(value, '-'),
                // to: `/erp/${value}`
            };
        }
        return {
            name: toTitleCase(value, '-'),
            // to: '/erp/' + splitLocation.slice(0, index + 1).join('/')
        };
    });
};


const SubMenuSection = () => {

    const { user } = useAuth();

    const location = useLocation();

    const breadcrumbs = getSections(location.pathname);

    const currentSection = sections.find(section => section.header?.toLowerCase() === breadcrumbs[0].name?.toLowerCase());

    const isSubSectionAuthorized = (subsection) => {
        let authorized = false
        const module = subsection.permission.replace(/ /g, "-").toLowerCase();
        const check = user?.userPermissions?.find(p => p.module === module)?.view || false;
        if (check) authorized = true;
        return authorized;
    }

    return (
        <Stack direction="row" spacing={2} alignItems="center">
            <Typography variant="h6" color="white">
                {currentSection?.header || breadcrumbs[0]?.name || 'Dashboard'}
            </Typography>
            {
                currentSection?.subSections?.length > 0 &&
                <Typography variant='h5' color={"white"}> | </Typography>
            }
            {currentSection?.subSections?.map((subSection, index) => {
                return (
                    isSubSectionAuthorized(subSection) &&
                    <Box
                        key={index}
                        component={RouterLink}
                        to={subSection.href}
                        sx={
                            subSection.permission?.toLowerCase() === breadcrumbs[1]?.name?.toLowerCase() ?
                            {
                                display: 'flex',
                                alignItems: 'center',
                                flexDirection:'column',
                                backgroundColor: theme => theme.palette.secondary.main,
                                padding: 1,
                                py: 0.5,
                                borderRadius: 2,
                                textDecoration: 'none',
                                color: 'white',
                                px: 4,
                            }
                            : {
                                display: 'flex',
                                padding: 1,
                                py: 0.5,
                                px: 4,
                                color: 'white',
                                textDecoration: 'none',
                                flexDirection:'column',
                                alignItems: 'center',
                                fontWeight:"bold",
                                '&:hover': {
                                    backgroundColor: theme => theme.palette.secondary.main,
                                    borderRadius: 2,
                                },
                            }
                        }
                    >
                        {/* <Icon size={24}  /> */}
                        <Typography key={index} variant="subtitle2"
                            sx={{
                                fontSize: 16,
                                fontWeight: subSection.permission === breadcrumbs[1]?.name ? "normal" : "normal",
                            }}
                        >
                            {subSection.title}
                        </Typography>
                    </Box>
                )
            })}
           
        </Stack>
    )
}

export default SubMenuSection

