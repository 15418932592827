import { Button } from '@mui/material';
import { styled } from '@mui/system';

const SaveButton = styled(Button)(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    '&:hover': {
        backgroundColor: theme.palette.primary.dark,
        color: 'white'
    }
}));

export default SaveButton;
