import { createSlice } from '@reduxjs/toolkit';
import instance from '../../utils/erpAxios';

const initialState = {
    employees: [],
    dataFetched: false,
    genders: [
        {
            id: 1,
            label: 'Male',
        },
        {
            id: 2,
            label: 'Female'
        }
    ],
    maritalStatus: [
        'Single',
        'Married',
        'Divorced',
        'Widowed',
        'Separated',
        'Other',
    ],
    jobTitles: [
        {
            label: "2nd Assistant"
        },
        {
            label: "Back Office Operator"
        },
        {
            label: "Betting Clerk"
        },
        {
            label: "Branch Manager"
        },
        {
            label: "Call Centre Manager"
        },
        {
            label: "Call Centre Operator"
        },
        {
            label: "Deputy Branch Manager"
        },
        {
            label: "Maintenance"
        },
        {
            label: "Senior Betting Clerk"
        },
        {
            label: "Shop Assistant"
        },
        {
            label: "Trainee"
        },
        {
            label: "Training Manager"
        }
    ]
};


export const hrSlice = createSlice({
    name: 'hr',
    initialState,
    reducers: {
        setAllEmployees: (state, action) => {
            state.employees = action.payload;
            state.dataFetched = true;
        },
    }
});


export const getEmployeeData = (userId) => async (dispatch) => {
    try {
        const response = await instance.get(`/employees/${userId}`);
        dispatch(hrSlice.actions.setAllEmployees(response.data));
    } catch (error) {
        return dispatch(hrSlice.actions.setAllEmployees(initialState.employees));
    }
};

export const clearEmployeeState = () => async (dispatch) => {
    dispatch(hrSlice.actions.setAllEmployees(initialState.employees));
    return;
};

export const {setAllEmployees} = hrSlice.actions;

export default hrSlice.reducer;
