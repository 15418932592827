import { createSlice } from '@reduxjs/toolkit';
import instance from '../../utils/erpAxios';


const initialState = {
    tasks: [],
};

export const taskSlice = createSlice({
    name: 'tasks',
    initialState,
    reducers: {
        setAllTasks: (state, action) => {
            state.tasks = action.payload;
        },
    }
});

export const getAllTasks = () => async (dispatch) => {
    try {
        const response = await instance.get(`/tasks`);
        dispatch(taskSlice.actions.setAllTasks(response.data));
    } catch (error) {
        return dispatch(taskSlice.actions.setAllTasks(initialState.tasks));
    }
};

export const clearTaskState = () => async (dispatch) => {
    return dispatch(taskSlice.actions.setAllTasks(initialState.tasks));
};

export const {setAllTasks} = taskSlice.actions;

export default taskSlice.reducer;
