import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../authentication/useAuth';

const AuthGuard = ({ children }) => {
    const { user, canView, canEdit, isValidToken, token } = useAuth();
    const location = useLocation();

    if (!user || !isValidToken(token)) {
        return <Navigate to="/erp/login" state={{ from: location }} replace />;
    }
    const { pathname } = location;
    const [, , , pathModule] = pathname.split('/');
    if (pathModule && pathModule !== 'not-found') {
        if (!(canEdit(pathModule) && canView(pathModule))) {
            return <Navigate to="/erp/dashboard/not-found" />;
        }
    }

    return children;
};

export default AuthGuard;
