import { configureStore } from '@reduxjs/toolkit';
import globalReducer from './global';
import userReducer from '../views/Administration/Users/slice';
import expenseReducer from '../views/Administration/Expenses/slice';
import branchReducer from '../views/Administration/Branches/slice';
import sheetsReducer from '../views/ExpensesManagement/slice';
import reportsReducer from '../views/ExpensesReports/slice';
import tasksReducer from '../views/Dashboard/slice';
import itSettingsReducer from '../views/ITSupport/Settings/slice';
import hrReducer from '../views/HumanResources/slice';

export const store = configureStore({
    reducer: {
        global: globalReducer,
        users: userReducer,
        expenses: expenseReducer,
        branches: branchReducer,
        expenseSheets: sheetsReducer,
        reports: reportsReducer,
        tasks: tasksReducer,
        itSettings: itSettingsReducer,
        hr: hrReducer,
    }
});
