export const toSentenceCase = (string) => string.charAt(0).toUpperCase() + string.slice(1);

export const toTitleCase = (string, separator = ' ') =>
    string
        .split(separator)
        .map((s) => toSentenceCase(s))
        .join(' ');

export const toURLCase = (string) => string.toLowerCase().split(' ').join('-');

export const toCamelCase = (string) => {
    const strings = string.split(' ');
    return strings.map((s, i) => (i === 0 ? s.toLowerCase() : toSentenceCase(s))).join('');
};

export const displayIntegerWithThousandsSeparator = (string) => {
    if (!string) return string;
    if (typeof string !== 'string') {
        string = string.toString();
    }
    return string.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};
