

import { Box, Button, Card, CardActions, CardContent, CardHeader, Modal, Stack, TextField } from '@mui/material'
import React from 'react'
import { Eye, EyeOff } from 'react-feather';
import { useAuth } from '../../authentication/useAuth';
import SaveButton from '../../components/SaveButton';
import useSnackbar from '../../hooks/useSnackbar';
import instance from '../../utils/erpAxios';

const CreateNewPasswordModal = ({open, setOpen}) => {

    const snackbar = useSnackbar();

    const { user, logout, refreshToken } = useAuth();

    const [newPassword, setNewPassword] = React.useState("");
    const [viewPassword, setViewPassword] = React.useState(false); 
    const [confirmPassword, setConfirmPassword] = React.useState("");
    const [viewConfirmPassword, setViewConfirmPassword] = React.useState(false);
    const [error, setError] = React.useState(false);
    const [errorText, setErrorText] = React.useState(null);

    const handleConfirm = async (e) => {
        e.preventDefault();
        try {
            await instance.post('/users/newPassword', { ...user, password: newPassword.trim() });
            refreshToken();
            setOpen(false);
            snackbar.openSnackbar('success', 'Password changed successfully');
        }
        catch (error) {
            setError(true);
            setErrorText(error.response.data);
            const allErrors = error.response.data.split(' | ');
            allErrors.forEach((e) => snackbar.openSnackbar('error', e));
            // snackbar.openSnackbar('error', 'Error while changing user password');
        }
    }

    const PasswordError = ({errorText}) => {
        const errorArray = errorText.split(' | ');
        if(errorArray) {
            return (
                <ol>
                    {
                        errorArray.map((e, index) => {
                            return <li key={index}>{e}</li>
                        })
                    }
                </ol>
            )
        }
        return null;
    }

    return (
        <Modal
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
            open={open}
        >
            <form onSubmit={handleConfirm}>
                <Card sx={{ p: 2, minWidth: '30vw' }}>
                    <CardHeader title="Create New Password" />
                    <CardContent>
                        <Stack gap={1}>
                            <TextField
                                fullWidth
                                label="New Password"
                                name="newPassword"
                                required
                                type={viewPassword ? "text" : "password"}
                                size="small"
                                value={newPassword}
                                error={error && errorText}
                                onChange={(event) => {
                                    if(confirmPassword.length && event.target.value !== confirmPassword) {
                                        setError(true);
                                    }
                                    else {
                                        setError(false);
                                    }
                                    setErrorText(null);
                                    return setNewPassword(event.target.value);
                                }}
                                InputProps={{
                                    endAdornment: 
                                        <Box
                                            onClick={() => setViewPassword(!viewPassword)}
                                            sx={{ cursor: 'pointer' }}
                                        >
                                            {
                                                viewPassword ? <EyeOff /> : <Eye />
                                            }
                                        </Box>
                                }}
                            />
                            <TextField
                                fullWidth
                                label="Confirm New Password"
                                name="confirmPassword"
                                required
                                type={viewConfirmPassword ? "text" : "password"}
                                size="small"
                                value={confirmPassword}
                                onChange={(event) => {
                                    if(newPassword.length && event.target.value !== newPassword) {
                                        setError(true);
                                    }
                                    else {
                                        setError(false);
                                    }
                                    setErrorText(null);
                                    return setConfirmPassword(event.target.value);
                                }}
                                error={error}
                                helperText={error ? errorText ? <PasswordError errorText={errorText} /> : "Passwords do not match" : ""}
                                InputProps={{
                                    endAdornment: 
                                        <Box
                                            onClick={() => setViewConfirmPassword(!viewConfirmPassword)}
                                            sx={{ cursor: 'pointer' }}
                                        >
                                            {
                                                viewConfirmPassword ? <EyeOff /> : <Eye />
                                            }
                                        </Box>
                                }}
                            />
                        </Stack>
                    </CardContent>
                    <CardActions>
                        <Stack width="100%" direction="row" alignItems={"center"} justifyContent="space-between">
                            <SaveButton variant="contained" color="primary" type="submit"
                                disabled={
                                    !newPassword.length || !confirmPassword.length || error
                                }
                            >Confirm</SaveButton>
                            <Button variant="outlined" color="error" onClick={logout}>Logout</Button>
                        </Stack>
                    </CardActions>
                </Card>
            </form>
        </Modal>
    )
}

export default CreateNewPasswordModal