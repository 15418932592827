import {  Drawer } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { setDrawerOpen } from '../../store/global';
import NavBar from './NavBar';
import TopBar from './TopBar';
import './styles.css';

const MainLayout = () => {
    const dispatch = useDispatch();
    const { drawerOpen } = useSelector(state => state.global);

    return (
        <div
            style={{
                display: 'flex',
                height: '100%',
                overflow: 'hidden',
                width: '100%',
                backgroundColor: '#f4f6f8'
            }}
        >
            <TopBar drawerOpen={drawerOpen} />
            {/* <Hidden xlDown >
                <NavBar />
            </Hidden> */}
            <Drawer
                open={drawerOpen}
                ModalProps={{ onBackdropClick: () => dispatch(setDrawerOpen()) }}
            >
                <NavBar />
            </Drawer>
                {/* <Container className='container'>
                    <SwitchTransition>
                        <CSSTransition
                        nodeRef={nodeRef}
                        timeout={3000}
                        classNames="page"
                        unmountOnExit
                        > */}
                            <div
                                style={{
                                    display: 'flex',
                                    flex: '1 1 auto',
                                    overflow: 'hidden',
                                    paddingTop: 64,
                                    height: '100%',
                                }}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        flex: '1 1 auto',
                                        overflow: 'hidden',
                                        height: '100%',
                                        width: '100%',
                                        padding: 16,
                                    }}
                                >
                                    <Outlet />
                                </div>
                            </div>
                        {/* </CSSTransition>
                    </SwitchTransition>
                </Container> */}
        </div>
    )
};

export default MainLayout;
