import { Fragment, lazy, Suspense } from 'react';
import { Navigate, Route } from 'react-router-dom';
import AuthGuard from './components/AuthGuard';
// import GlobalGuard from './components/GlobalGuard';
import SplashScreen from './components/SplashScreen';
import MainLayout from './layouts/MainLayout';
import Dashboard from './views/Dashboard';
import Login from './views/Login/Login';

const Page404 = lazy(() => import('./components/Page404'));

export const renderRoutes = (routes = []) => {
    return routes.map((route, i) => {
        const { component: Component, guard, props = {}, routes = [], index = false, path } = route || {};
        const Guard = guard || Fragment;

        return (
            <Route
                key={i}
                index={index}
                path={path}
                element={
                    <Guard>
                        <Suspense fallback={<SplashScreen />}>
                            <Component {...props} />
                        </Suspense>
                    </Guard>
                }
            >
                {routes.length ? renderRoutes(routes) : null}
            </Route>
        );
    });
};

const routes = [
    {
        path: '/',
        component: () => <Navigate to="/erp/" />
    },
    {
        path: '/erp/login',
        component: Login
    },
    {
        path: '/erp',
        component: MainLayout,
        guard: AuthGuard,
        routes: [
            {
                index: true,
                component: Dashboard
            },
            {
                path: '/erp/administration/users',
                component: lazy(() => import('./views/Administration/Users/UsersAdmin')),
            },
            {
                path: '/erp/administration/branches',
                component: lazy(() => import('./views/Administration/Branches/BranchesAdmin')),
            },
            {
                path: '/erp/administration/expenses',
                component: lazy(() => import('./views/Administration/Expenses/ExpensesAdmin')),
            },
            {
                path: '/erp/expenses/expenses-summary',
                component: lazy(() => import('./views/ExpensesReports/Summary')),
                props: {
                    isBranch: true
                }
            },
            {
                path: '/erp/expenses/monthly-expenses',
                component: lazy(() => import('./views/ExpensesReports/MonthlyReport')),
            },
            {
                path: '/erp/expenses/report-builder',
                component: lazy(() => import('./views/ExpensesReports/ReportBuilder')),
            },
            {
                path: '/erp/expenses/expense-sheets',
                component: lazy(() => import('./views/ExpensesManagement/BranchExpenses')),
                props: {
                    isBranch: true
                }
            },
            // {
            //     path: '/erp/hotspot/hotspot-overview',
            //     component: lazy(() => import('./views/ExpensesReports/MonthlyReport')),
            // },
            // {
            //     path: '/erp/it-support/it-support-overview',
            //     component: lazy(() => import('./views/ITSupport/Overview/ITSupportOverview')),
            // },
            // {
            //     path: '/erp/it-support/it-support-tickets',
            //     component: lazy(() => import('./views/ITSupport/Tickets/ITSupportTickets')),
            // },
            // {
            //     path: '/erp/it-support/it-support-settings',
            //     component: lazy(() => import('./views/ITSupport/Settings/ITSupportSettings')),
            // },
            {
                path: '/erp/human-resources/directory',
                component: lazy(() => import('./views/HumanResources/Directory/Directory')),
            },
            {
                path: '*',
                component: Page404
            }
        ]
    }
];

export default routes;
