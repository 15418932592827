import { createSlice } from '@reduxjs/toolkit';
import instance from '../../../utils/erpAxios';


const initialState = {
    branches: [],
    enabledBranches: [],
    categories: []
};

export const branchSlice = createSlice({
    name: 'branches',
    initialState,
    reducers: {
        setAllBranches: (state, action) => {
            state.branches = action.payload;
        },
        setAllCategories: (state, action) => {
            const payload = action.payload;
            const logout = action.payload.logout;
            if(logout) {
                state.categories = [];
                return;
            }
            state.categories = payload.concat({categoryName: 'Branches'});
        },
        setAllEnabledBranches: (state, action) => {
            state.enabledBranches = action.payload;
        }

    }
});

export const getAllBranches = (userId) => async (dispatch) => {
    try {
        const response = await instance.get(`/branches/${userId}`);
        dispatch(branchSlice.actions.setAllBranches(response.data));
    } catch (error) {
        return dispatch(branchSlice.actions.setAllBranches(initialState.branches));
    }
};

export const getAllEnabledBranches = (userId) => async (dispatch) => {
    try {
        const response = await instance.get(`/branches/enabled/${userId}`);
        dispatch(branchSlice.actions.setAllEnabledBranches(response.data));
    } catch (error) {
        return dispatch(branchSlice.actions.setAllEnabledBranches(initialState.enabledBranches));
    }
}

export const getAllCategories = (userId) => async (dispatch) => {
    try {
        const response = await instance.get(`/categories/${userId}`);
        dispatch(branchSlice.actions.setAllCategories(response.data));
    } catch (error) {
        return dispatch(branchSlice.actions.setAllCategories(initialState.categories))
    }
}

export const clearBranchState = (logout) => async (dispatch) => {
    dispatch(branchSlice.actions.setAllBranches(initialState.branches));
    dispatch(branchSlice.actions.setAllEnabledBranches(initialState.enabledBranches));
    dispatch(branchSlice.actions.setAllCategories(initialState.categories, logout));
    return;
};

export const {setAllBranches, setAllCategories} = branchSlice.actions;

export default branchSlice.reducer;
