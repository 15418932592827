import { Box, Card, CardContent, CardHeader, Grid, Hidden, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { AlertCircle, DollarSign, ExternalLink, MapPin, PieChart, Table, Users } from "react-feather";
import {  useSelector } from "react-redux";
import { NavLink as RouterLink } from 'react-router-dom'
import { useAuth } from "../../authentication/useAuth";
import { theme } from "../../theme";
import CreateNewPasswordModal from "../Login/CreateNewPasswordModal";
// import { getAllTasks } from "./slice";


const Dashboard = () => {

    const { user, canView, canEdit } = useAuth();

    const { tasks } = useSelector(state => state.tasks);
    // const dispatch = useDispatch();

    const [confirmPasswordModalOpen, setConfirmPasswordModalOpen] = useState(false);

    // useEffect(() => {
    //     if (!tasks.length) {
    //         dispatch(getAllTasks());
    //     }
    // }, [tasks, dispatch])

    useEffect(() => {
        if (user) {
            setConfirmPasswordModalOpen(user.firstLogin);
        }
    }, [user])

    const quickLinks = [
        {
            title: 'View Users',
            icon: <Users />,
            link: '/erp/administration/users',
            permission: 'users'
        },
        {
            title: 'View Expenses',
            icon: <DollarSign />,
            link: '/erp/administration/expenses',
            permission: 'expenses'
        },
        {
            title: 'View Branches',
            icon: <MapPin />,
            link: '/erp/administration/branches',
            permission: 'branches'
        },
        {
            title: 'View Expense Sheets',
            icon: <Table />,
            link: '/erp/expenses/expense-sheets',
            permission: 'expense-sheets'
        },
        {
            title: 'Expenses Summary',
            icon: <PieChart />,
            link: '/erp/expenses/expenses-summary',
            permission: 'expenses-summary'
        },
        {
            title: 'View Employees',
            icon: <Users />,
            link: '/erp/human-resources/directory',
            permission: 'directory'
        }
    ]

    return (
        <>
            <CreateNewPasswordModal open={confirmPasswordModalOpen} setOpen={setConfirmPasswordModalOpen} />
            <Grid container spacing={2} justifyContent="center">
                <Grid item xs={12}>
                    <Card>
                        <CardHeader title={`Welcome, ${user.firstName} ${user.lastName}`} titleTypographyProps={{ fontSize: 24 }} sx={{ textAlign: 'center' }} />
                        <CardContent sx={{ pt: 0 }}>
                            <Stack direction="row" spacing={4} justifyContent="space-around" alignItems="center" p={1} pt={0}>
                                <Typography fontSize={14} variant="h6" textAlign={"justify"}>
                                    <div>
                                        This is the Enterprise Resource Planning (ERP) application for MWOS. You can use the quick links to navigate to the different pages in the application. If you have pending tasks, they will be displayed on this page. For application support, please email &nbsp;
                                        <a href="mailto:matthew@betting.co.zw:">matthew@betting.co.zw</a>.
                                    </div>
                                </Typography>
                            </Stack>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} lg={6}
                    sx={{
                        borderRight: { xs: 0, lg: `1px solid ${theme.palette.divider}` },
                    }}
                >
                    <Typography variant="h6" sx={{ textAlign: 'left', mb: 1, color: theme.palette.primary.main }}>Quick Links</Typography>
                    <Grid container spacing={2} justifyContent="start" alignItems={"flex-start"}
                        sx={{
                            '& .MuiGrid-item': {
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }

                        }}
                    >
                        {
                            quickLinks.map(link => {
                                if (canView(link.permission) && canEdit(link.permission)) {
                                    return (
                                        <Grid item xs={4} sm={3} md={2} key={link.permission}>
                                            <Box
                                                width="84px"
                                                height="84px"
                                                display="flex"
                                                flexDirection="column"
                                                alignItems="center"
                                                justifyContent="center"
                                                gap={0.5}
                                                p={1}
                                                sx={{
                                                    backgroundColor: "white",
                                                    textDecoration: "none",
                                                    boxShadow: 6,
                                                    color: theme.palette.primary.main
                                                }}
                                                component={RouterLink}
                                                borderRadius={2}

                                                to={link.link}
                                            >
                                                {link.icon}
                                                <Typography fontSize={12} variant="subtitle2">
                                                    {link.title}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    )
                                }
                                else return null
                            })
                        }
                    </Grid>
                    <Hidden lgDown>
                        <Grid item xs={12}>
                            <img src="/mwos_logo_blue.svg" alt="logo" style={{ marginTop: '20%' }} />
                        </Grid>
                    </Hidden>
                </Grid>
                <Grid item xs={12} lg={6} justifyContent={"center"} alignItems="center">
                    <Typography variant="h6" sx={{ textAlign: 'left', mb: 1, color: theme.palette.primary.main }}>Pending Tasks</Typography>
                    <Box sx={{ minHeight: 'calc(100vh - 300px)' }}>
                        <Stack direction="column" spacing={2} justifyContent="flex-start" alignItems="start" p={1} pt={0}>
                            {
                                tasks.length > 0 ?
                                    tasks.map(task => (
                                        <Box
                                            display="flex"
                                            flexDirection="row"
                                            alignItems="center"
                                            justifyContent="space-between"
                                            p={2}
                                            sx={{
                                                backgroundColor: "white",
                                                textDecoration: "none",
                                                boxShadow: 6,
                                                width: 'calc(100% - 24px)'
                                            }}
                                            component={RouterLink}
                                            borderRadius={2}
                                            to={'/erp/expenses/expense-sheets/'}
                                        >
                                            <Stack direction="row" alignItems={"center"} gap={2}>
                                                {
                                                    task.taskPriority === 'High' &&
                                                    <AlertCircle size={40} color={theme.palette.secondary.main} />
                                                }
                                                <Stack direction="column" spacing={1} justifyContent="flex-start" alignItems="start">
                                                    <Typography fontSize={16} variant="subtitle2">
                                                        {task.taskName}
                                                    </Typography>
                                                    <Typography fontSize={12} variant="body1">
                                                        {task.taskDescription}
                                                    </Typography>
                                                </Stack>
                                            </Stack>
                                            <ExternalLink />
                                        </Box>
                                    ))
                                    :
                                    <Typography fontSize={12} variant="subtitle2">
                                        You have no pending tasks.
                                    </Typography>
                            }

                        </Stack>
                    </Box>
                </Grid>
            </Grid>
        </>
    );
};

export default Dashboard;