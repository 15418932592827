import { Autocomplete, Box, Drawer, Stack, TextField, Typography } from '@mui/material'
import React from 'react'
import { X } from 'react-feather'

const QuickAddDrawer = ({open, setOpen}) => {
    return (
        <Drawer
            variant="temporary"
            anchor="right"
            open={open}
            onClose={() => setOpen(false)}
            ModalProps={{
                keepMounted: true // Better open performance on mobile.
            }}
            sx={{
                '& .MuiDrawer-paper': {
                    width: `${300}px`,
                    boxSizing: 'border-box',
                    border: 'none',
                    boxShadow: 'none',
                    p: 0,
                    height: 'calc(100%)'
                }
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    p: 2,
                }}
            >
                <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ mb: 2 }}>
                    <Typography variant='h6' color={'#312783'}>Quick Add</Typography>
                    <X 
                        onClick={() => setOpen(false)}
                        color="#312783"
                        style={{
                            cursor: 'pointer'
                        }}
                    />
                </Stack>
                <Autocomplete 
                    disablePortal
                    options={['General', 'Airtime', 'Wages']}
                    renderInput={(params) => <TextField {...params} size="small" label="Expense Type" variant="outlined" />}
                    sx={{ mb: 2 }}
                />
            </Box>
        </Drawer>
    )
}

export default QuickAddDrawer